import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Dropdown, Menu, Layout } from "antd";
import { useEffect, useState } from "react";
import { Button, Link } from "components";
import { CaretDownOutlined } from "@ant-design/icons";
import useMediaQuery from "hooks/useMediaQuery";
import logo from "assets/images/logo-full.png";
import menuIcon from "assets/images/menu-icon.png";
import closeIcon from "assets/images/close-menu-icon.png";
import locales from "localisation";
import formLinks from "utils/formLinks";
import _ from "lodash";
const { Header } = Layout;

const sections = [
  "home",
  "aboutUs",
  "features",
  "pricing",
  "demo",
  "faq"
]


const LanguageDropdown = () => {

  const isMobile = useMediaQuery('(max-width: 1360px)');
  const { t, i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState()

  const setLanguage = (key) => {
    setSelectedLanguage(key)
    setTimeout(() => i18n.changeLanguage(key), 0)
    localStorage.setItem('lang', key)
  }

  useEffect(() => {
    const savedLang = localStorage.getItem('lang');
    if (savedLang) {
      setLanguage(savedLang)
    } else {
      setLanguage('en')
    }
  }, [])

  return (
    <>
      <Dropdown
        dropdownRender={() => (
          <StyledMenu 
            onClick={({ key }) => setLanguage(key)} 
            selectedKeys={[selectedLanguage]} 
            items={_.keys(locales).map((key) => ({ key, label: (<Link>{_.upperCase(key)}</Link>) }))} 
          />
        )}
        placement={!isMobile ? 'bottomRight' : 'bottomLeft'}
      >
        <Link onClick={(e) => e.preventDefault()}>
          {isMobile && t("globals.language") + " : "}{_.upperCase(selectedLanguage)}
          <StyledCaret />
        </Link>
      </Dropdown>
    </>
  )
}
const StyledCaret = styled(CaretDownOutlined)`
  margin-left: 10px;
`;

const AppHeader = () => {

  const isMobile = useMediaQuery('(max-width: 1360px)');
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)

  if (isMobile) {
    return (
      <>
        <StyledHeader>
          <StyledImg src={logo} alt="F5" />
          <MenuContainer>
            <MenuImg 
              src={menuIcon}
              alt="Menu button" 
              onClick={() => setIsExpanded(true)} 
              $active={!isExpanded}
            />
            <MenuImg 
              src={closeIcon}
              alt="Menu button" 
              onClick={() => setIsExpanded(false)} 
              $active={isExpanded}
            />
          </MenuContainer>
        </StyledHeader>
        <Expandable $active={isExpanded}>
          {sections.map((key) => (
            <Link 
              key={key} 
              onClick={() => setIsExpanded(false)}
              href={`/#${key}`}
            >{t(`globals.${key}`)}</Link>
          ))}  
          <ContactButton 
            onClick={() => setIsExpanded(false)} 
            size="large"
            href="https://dashboard.f5qr.me/"
          >
            {t("globals.features")}
          </ContactButton>
          <Link 
            onClick={() => setIsExpanded(false)} 
            href="https://weavertechnologiesbv.pipedrive.com/scheduler/dwEAZyS5/digitale-presentatie-f5-scan"
            target="_blank"
          >{t("globals.scheduleDemo")}</Link>  
          <LanguageDropdown />
        </Expandable>
      </>
    );
  } else {
    return (
      <StyledHeader>
        <div>
          <StyledImg src={logo} alt="F5" />
        </div>
        <Links>
          {sections.map((key) => <Link key={key} href={`/#${key}`}>{t(`globals.${key}`)}</Link>)}  
          <ContactButton 
            onClick={() => setIsExpanded(false)} 
            size="large"
            href="https://dashboard.f5qr.me/"
          >
            {t("globals.features")}
          </ContactButton>    
        </Links>
        <div>
          <LanguageDropdown />
          <ContactButton 
            onClick={() => setIsExpanded(false)} 
            size="large"
            type="primary"
            href="https://weavertechnologiesbv.pipedrive.com/scheduler/dwEAZyS5/digitale-presentatie-f5-scan"
            target="_blank"
          >
            {t("globals.scheduleDemo")}
          </ContactButton>
        </div>
      </StyledHeader>
    )
  }
}

const StyledMenu = styled(Menu)`
  @media(min-width: 576px) {
    min-width: 200px;
  }
  border: 1px solid var(--border-color);
  &.ant-dropdown-menu {
    background: var(--primary-color-darker);
  }
  &.ant-dropdown-menu .ant-dropdown-menu-item-selected {
    background: var(--primary-green);
    & .ant-dropdown-menu-title-content {
      color: var(--primary-color-dark);
      font-weight: 600;
    }
  }
  & .ant-dropdown-menu-title-content {
    color: var(--text-color);
  }
`;
const MenuContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
`;
const Expandable = styled.div`
  background: var(--primary-color-dark);
  position: fixed;
  height: calc(100vh - 64px);
  width: 100%;
  top: 90px;
  z-index: 10;
  transform: ${props => (
   props.$active ? 'scaleY(1)' : 'scaleY(0)'
  )};
  transition: transform 150ms ease-in-out;
  display: flex;
  flex-direction: column;
  & ${Link} {
    background: #0A1217;
    border-bottom: 1px solid #101820;
    padding: 24px 0;
    font-weight: 500;
    color: var(--text-color);
    font-size: 16px;
    & span {
      text-decoration: underline;
    }
  }
`;
const ContactButton = styled(Button)`
  &&& {
    border-radius: 30px;
    margin-left: 20px;
    margin-right: 20px;
    max-height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;
const Links = styled.div`
  & > ${Link} {
    margin: 0 16px;
  }
`;
const StyledImg = styled.img`
  height: 36px;
`;
const MenuImg = styled(StyledImg)`
  height: 20px;
  opacity: ${props => props.$active ? 1 : 0};
  transform: ${props => props.$active ? 'translate(0px, 0)' : 'translate(0px, -50px)'};
  transition: all 300ms;
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0 20px;
`;
const StyledHeader = styled(Header)`
  background: var(--primary-color-dark);
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0px 0px 20px #00000033;
  height: 90px;
  & ${Link} {
    font-size: 16px;
  }
  &.ant-layout-header {
    padding: 0 40px;
  }
  overflow: visible;
  & > div {
    min-width: 200px;
  }
  & > div, > div ${ContactButton} {
    display: flex;
    align-items: center;
  }
`;
export default AppHeader;